import {
  isRouteErrorResponse,
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldReloadFunction,
  useLoaderData,
  useRouteError } from
'@remix-run/react';
import {
  json,
  MetaFunction,
  SerializeFrom } from
'@remix-run/server-runtime';
import { useState } from 'react';
import Footer from '~/components/footer/Footer';
import { APP_META_DESCRIPTION, APP_META_TITLE } from '~/constants';
import { CartTray } from './components/cart/CartTray';
import { Header } from './components/header/Header';
import styles from './styles/app.css';
import { useActiveOrder } from './utils/use-active-order';
import { Toaster } from 'react-hot-toast';

export function loader() {
  const ENV = {
    appUrl: process.env.APP_URL ?? '',
    apiUrl: process.env.API_URL ?? ''
  };
  return json({ ENV });
}
export type LoaderData = SerializeFrom<typeof loader>;

export const meta: MetaFunction = () => {
  return { title: APP_META_TITLE, description: APP_META_DESCRIPTION };
};

export function links() {
  return [
  { rel: 'stylesheet', href: styles },
  { rel: 'stylesheet', href: '/neon/1.2.0/css/neon-styles.css' },
  { rel: 'stylesheet', href: '/neon/1.2.0/css/fonts.css' }];

}

const devMode =
typeof process !== 'undefined' && process.env.NODE_ENV === 'development';

// The root data does not change once loaded.
export const unstable_shouldReload: ShouldReloadFunction = ({
  url,
  prevUrl,
  params,
  submission
}) => {
  if (prevUrl.pathname === '/sign-in') {
    // just logged in
    return true;
  }
  if (prevUrl.pathname === '/account' && url.pathname === '/') {
    // just logged out
    return true;
  }
  if (submission?.action === '/checkout/payment') {
    // submitted payment for order
    return true;
  }
  return false;
};

export type RootLoaderData = {


  // activeChannel: Awaited<ReturnType<typeof activeChannel>>;
  // collections: Awaited<ReturnType<typeof getCollections>>;
};


export default function App() {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const loaderData = useLoaderData<RootLoaderData>();

  const {
    activeOrderFetcher,
    activeOrder,
    adjustOrderLine,
    removeItem,
    refresh
  } = useActiveOrder();

  return (
    <html lang="en" id="app">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="icon" href="/favicon.ico" type="image/png"></link>
        <Meta />
        <Links />
      </head>
      <body>
      <Toaster toastOptions={{ duration: 5000 }} />
        <Header
          onCartIconClick={() => setIsCartOpen(!isCartOpen)}
          cartQuantity={activeOrder?.totalQuantity ?? 0} />

        <main className="sm:pt-12 xl:max-w-7xl xl:mx-auto py-8 px-8">
          <Outlet context={{
            activeOrderFetcher,
            activeOrder,
            adjustOrderLine,
            removeItem,
            setIsCartOpen,
            refresh
          }} />
        </main>
        <CartTray
          open={isCartOpen}
          onClose={setIsCartOpen}
          activeOrder={activeOrder}
          adjustOrderLine={adjustOrderLine}
          removeItem={removeItem} />

        <ScrollRestoration />
        <Scripts />

        <Footer collections={[]}></Footer>

        {devMode && <LiveReload />}
      </body>
    </html>);

}



type DefaultSparseErrorPageProps = {
  tagline: string;
  headline: string;
  description: string;
};
/**
 * You should replace this in your actual storefront to provide a better user experience.
 * You probably want to still show your footer and navigation. You will also need fallbacks
 * for your data dependant components in case your shop instance / CMS isnt responding.
 * See: https://remix.run/docs/en/main/route/error-boundary
 */
function DefaultSparseErrorPage({ tagline, headline, description }: DefaultSparseErrorPageProps) {
  return (
    <html lang="en" id="app">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="icon" href="/favicon.ico" type="image/png"></link>
        <script src="/api/categories"></script>

        <Meta />
        <Links />
      </head>
      <body>
        <main className="flex flex-col items-center px-4 py-16 sm:py-32 text-center">
          <span className="text-sm font-semibold text-gray-500 uppercase tracking-wide">{tagline}</span>
          <h1 className="mt-2 font-bold text-gray-900 tracking-tight text-4xl sm:text-5xl">{headline}</h1>
          <p className="mt-4 text-base text-gray-500 max-w-full break-words">{description}</p>
          <div className="mt-6">
            <Link
              to="/"
              className="text-base font-medium text-primary-600 hover:text-primary-500 inline-flex gap-2">

              Go back home
            </Link>
          </div>
        </main>
        <ScrollRestoration />
        <Scripts />
        {devMode && <LiveReload />}
      </body>
    </html>);

}

/**
 * As mentioned in the jsdoc for `DefaultSparseErrorPage` you should replace this to suit your needs.
 */
export function ErrorBoundary() {
  let tagline = "Oopsy daisy";
  let headline = "Unexpected error";
  let description = "We couldn't handle your request. Please try again later.";

  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    tagline = `${error.status} error`;
    headline = error.statusText;
    description = error.data;
  }

  return <DefaultSparseErrorPage tagline={tagline} headline={headline} description={description} />;
}

/**
 * In Remix v2 there will only be a `ErrorBoundary`
 * As mentioned in the jsdoc for `DefaultSparseErrorPage` you should replace this to suit your needs.
 * Relevant for the future: https://remix.run/docs/en/main/route/error-boundary-v2
 */
export function CatchBoundary() {
  return ErrorBoundary();
}