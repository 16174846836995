import { MagnifyingGlassIcon, ListBulletIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from '@remix-run/react';
import { useState } from 'react';
import { SearchBar } from '~/components/header/SearchBar';
import { useRootLoader } from '~/utils/use-root-loader';

export function Header({
  onCartIconClick,
  cartQuantity,
}: {
  onCartIconClick: () => void;
  cartQuantity: number;
}) {
  const data = useRootLoader();
  const isSignedIn = false;
  const isScrollingUp = false; // useScrollingUp();
  
  const [state, setState] = useState<{ showSearch: boolean }>({ showSearch: false });

  const onShowSearch = () => {
    setState({ showSearch: true});
  }


  return (
    <header
      className="bg-w sticky top-0 shadow-lg transform shadow-xl relative z-40 pl-6 pr-12"
    >

      <div className="max-w-6xl h-20 flex justify-between mx-auto">
        <h1 className="w-30 self-center py-2 pr-4">
          <Link to="/" className="cursor-pointer">
            <img
              src="https://www.powerschool.com/wp-content/themes/powerschool/images/logo.svg"
              width={170}
              alt="Powerschool logo"
            />
          </Link>
        </h1>
        {/* <div className="flex space-x-4 hidden sm:block">
          {data.collections.map((collection) => (
            <Link
              className="text-sm md:text-base text-gray-200 hover:"
              to={'/collections/' + collection.slug}
              prefetch="intent"
              key={collection.id}
            >
              {collection.name}
            </Link>
          ))}
        </div> */}
         
            <div className="flex-1 self-center md:pl-8">
            { state.showSearch ? ( <SearchBar></SearchBar> ) : '' }
            
            </div>

       
        <div className="header-buttons mt-6">
        { !state.showSearch ? (
          <button
          className="relative bg-white bg-opacity-20 rounded p-1"
          onClick={() => onShowSearch() }
          aria-label="Show search"
          >
          <MagnifyingGlassIcon></MagnifyingGlassIcon><span className="ml-1 header-button-text">Search</span>
          </button>
        ) : (
          <button className="relative w-9 h-9 bg-white bg-opacity-20 rounded p-1 text-secondary" onClick={() => setState({ showSearch: false }) }>
                  <XMarkIcon></XMarkIcon>
                </button>) }
          { <button
            className="relative bg-white bg-opacity-20 rounded p-1 ml-3"
            onClick={onCartIconClick}
            aria-label="Open cart tray"
          >
            <ListBulletIcon></ListBulletIcon><span className="ml-1 header-button-text">Quote List</span>
            {cartQuantity ? (
              <div className="cart-quantity absolute rounded-full bg-primary text-white font-bold w-6 h-6 flex items-center justify-center">
                {cartQuantity}
              </div>
            ) : (
              ''
            )}
          </button> }
        </div>
        {/* <div>
            <Link
              to={isSignedIn ? '/account' : '/sign-in'}
              className="flex space-x-1"
            >
              <UserIcon className="w-7 h-7"></UserIcon>
              <span>{isSignedIn ? 'My Account' : 'Sign In'}</span>
            </Link>
          </div> */}
      </div>
    </header>
  );
}
